body{
  counter-reset: h2counter;
}
.post-content{
  &>h1 {
      counter-reset: h2counter;
  }
  &>h2:before {
      content: counter(h2counter) ".\0000a0\0000a0";
      counter-increment: h2counter;
      counter-reset: h3counter;
  }
  &>h3:before {
      content: counter(h2counter) "." counter(h3counter) ".\0000a0\0000a0";
      counter-increment: h3counter;
  }
}